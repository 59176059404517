export const ANALYTICS_EVENT = {
  NOT_SIGNIN: "not_sigin",
  ERROR_MESSAGE_API: "error_message_api",

  // RegisterNowScreen
  REG_REGISTER_NOW_BTN: "reg_register_now_btn",
  REG_SIGN_IN_BTN: "reg_sign_in_btn",

  // SignInScreen
  SIGN_IN_SUBMIT: "sign_in_submit",
  SIGN_IN_SUBMIT_SUCCESS: "sign_in_submit_success",
  SIGN_IN_SUBMIT_ERROR: "sign_in_submit_error",
  SIGN_IN_FORGOT_PASSWORD_BTN: "sign_in_forgot_password_btn",
  SIGN_IN_SIGN_UP_BTN: "sign_in_sign_up_btn",

  // SignUpScreen
  SIGN_UP_SUBMIT: "sign_up_submit",
  SIGN_UP_SUBMIT_SUCCESS: "sign_up_submit_success",
  SIGN_UP_SUBMIT_ERROR: "sign_up_submit_error",
  SIGN_UP_ALREADY_MEMBER_BTN: "sign_up_already_member_btn",
  SIGN_UP_TERMS_OF_SERVICE_BTN: "sign_up_terms_of_service_btn",
  SIGN_UP_PRIVACY_POLICY_BTN: "sign_up_privacy_policy_btn",

  // ForgotPasswordScreen
  FORGOT_PASSWORD_SUBMIT: "forgot_password_submit",
  FORGOT_PASSWORD_SUBMIT_SUCCESS: "forgot_password_submit_success",
  FORGOT_PASSWORD_SUBMIT_ERROR: "forgot_password_submit_error",

  // OtpVerifyScreen
  OTP_VERIFICATION_SUBMIT: "otp_verification_submit",
  OTP_VERIFICATION_SUBMIT_ERROR: "otp_verification_submit_error",
  OTP_VERIFICATION_SUBMIT_SUCCESS: "otp_verification_submit_success",
  OTP_VERIFICATION_RE_SEND_OTP_BTN_ERROR:
    "otp_verification_re_send_otp_btn_error",
  OTP_VERIFICATION_RE_SEND_OTP_BTN_SUCCESS:
    "otp_verification_re_send_otp_btn_success",

  // HomeScreen
  HOME_VIEW_ABCD_VIDEO: "home_view_abcd_video",
  HOME_VIEW_ABCD_RESULT_BTN: "home_view_abcd_result_btn",
  HOME_MY_HEALTH_TO_DO_PICK_BTN: "home_my_health_to_do_pick_btn",
  HOME_MY_HEALTH_TO_DO_CARD_BTN: "home_my_health_to_do_card_btn",
  HOME_VIDEO_VIEW_ALL: "home_video_view_all",
  HOME_ASK_DR_O: "home_ask_dr_o",
  UPGRADE_TO_PRO_PLAN_BTN: "upgrade_to_pro_plan_btn",

  // ABCDScreen
  ABCD_ADD_BTN: "abcd_add_btn",
  ABCD_EDIT_BTN: "abcd_edit_btn",
  ABCD_SUBMIT: "abcd_submit",
  ABCD_SUBMIT_SUCCESS: "abcd_submit_success",
  ABCD_SUBMIT_ERROR: "abcd_submit_error",

  // TodoScreen
  TODO_ADD: "todo_add",
  TODO_ADD_SUBMIT: "todo_add_submit",
  TODO_ADD_SUBMIT_SUCCESS: "todo_add_submit_success",
  TODO_ADD_SUBMIT_ERROR: "todo_add_submit_error",
  TODO_MARK_AS_COMPLETE_SUBMIT: "todo_mark_as_complete_submit",
  TODO_MARK_AS_COMPLETE_SUBMIT_SUCCESS: "todo_mark_as_complete_submit_success",
  TODO_MARK_AS_COMPLETE_SUBMIT_ERROR: "todo_mark_as_complete_submit_error",
  TODO_H_P_SCREEN_VIEW: "todo_h_p_screen_view",
  TODO_DELETE: "todo_delete",

  // MylabScreen
  LABS_BUY_LABS_BTN: "labs_buy_labs_btn",
  LABS_UPLOAD_LABS_BTN: "labs_upload_labs_btn",
  LABS_UPLOAD_LABS_SUBMIT_SUCCESS: "labs_upload_labs_submit_success",
  LABS_UPLOAD_LABS_SUBMIT_ERROR: "labs_upload_labs_submit_error",
  LABS_SEE_RESULT: "labs_see_result",
  LABS_DELETE_RESULT: "labs_delete_result",

  // ForumScreen
  FORUM_ARE_YOU_18: "forum_are_you_18",

  // VideoScreen
  VIDEO_RECENT_TAB: "video_recent_tab",
  VIDEO_MAILBAG_TAB: "video_mailbag_tab",
  VIDEO_SEARCH: "video_search",

  // DoctorScreen
  ASK_MEETING_CREATE_BTN: "ask_meeting_create_btn",
  ASK_MEETING_CREATE_SUBMIT_SUCCESS: "ask_meeting_create_submit_success",
  ASK_MEETING_CREATE_SUBMIT_ERROR: "ask_meeting_create_submit_error",
  ASK_MEETING_CONNECT: "ask_meeting_connect",

  // Menu Header
  MENU_PRFOILE_ICON_CLICK: "menu_prfoile_icon_click",
  MENU_HAMBURGER_ICON_CLICK: "menu_hamburger_icon_click",
  MENU_VIEW_PROFILE: "menu_view_profile",
  MENU_HOME_CLICK: "menu_home_click",
  MENU_MY_HEALTH_TO_DO_LIST_CLICK: "menu_my_health_to_do_list_click",
  MENU_H_P_KNOWLEDGE_BASE_CLICK: "menu_h_p_knowledge_base_click",
  MENU_LABS_CLICK: "menu_labs_click",
  MENU_FORUMS_CLICK: "menu_forums_click",
  MENU_BECOME_A_PROVIDER_CLICK: "menu_become_a_provider_click",
  MENU_FIND_A_PROVIDER_CLICK: "menu_find_a_provider_click",
  MENU_VIDEO_CLICK: "menu_video_click",
  MENU_ASK_DR_O_GPT_CLICK: "menu_ask_dr_o_gpt_click",
  MENU_ASK_THE_DOC_LIVE_CLICK: "menu_ask_the_doc_live_click",
  MENU_BILLING_CLICK: "menu_billing_click",
  MENU_USER_SETTINGS_CLICK: "menu_user_settings_click",
  MENU_SUPPORT_CLICK: "menu_support_click",
  MENU_ABOUT_CLICK: "menu_about_click",
  MENU_TERMS_OF_SERVICE_CLICK: "menu_terms_of_service_click",
  MENU_PRIVACY_POLICY_CLICK: "menu_privacy_policy_click",
  MENU_LOG_OUT_CLICK: "menu_log_out_click",

  // MembershipScreen
  BILLING_RESTORE_CLICK: "billing_restore_click",
  BILLING_SUBSCRIPTION_CLICK: "billing_subscription_click",
  BILLING_SUBSCRIPTION_SUCCESS: "billing_subscription_success",
  BILLING_SUBSCRIPTION_ERROR: "billing_subscription_error",
  BILLING_CANCEL_SUBSCRIPTION: "billing_cancel_subscription",

  // SettingsScreen
  DELETE_ACCOUNT_BTN: "delete_account_btn",
  DELETE_ACCOUNT_SUCCESS: "delete_account_success",
  PASSWORD_SUBMIT_BTN: "password_submit_btn",
  PASSWORD_SUBMIT_SUCCESS: "password_submit_success",
  PASSWORD_SUBMIT_ERROR: "password_submit_error",

  // CustomerSupportScreen
  SUPPORT_SUBMIT_SUCCESS: "support_submit_success",
  SUPPORT_SUBMIT_ERROR: "support_submit_error",

  // Top Navigation
  TOP_NAV_LOGO_BTN: "top_nav_logo_btn",
  TOP_NAV_ABOUT_BTN: "top_nav_about_btn",
  TOP_NAV_SIGN_IN_BTN: "top_nav_sign_in_btn",
  TOP_NAV_SIGN_UP_BTN: "top_nav_sign_up_btn",
  TOP_NAV_FIND_A_PROVIDER_BTN: "top_nav_find_a_provider_btn",
  TOP_NAV_BECOME_A_PROVIDER_BTN: "top_nav_become_a_provider_btn",
  TOP_NAV_BUY_LAB_BTN: "top_nav_buy_lab_btn",
  TOP_NAV_MENU_BTN: "top_nav_menu_btn",
  TOP_NAV_GET_THE_APP_BTN: "top_nav_get_the_app_btn",

  // LandingScreen
  LANDING_FIND_A_PROVIDER_BTN: "landing_find_a_provider_btn",
  LANDING_BECOME_A_PROVIDER_BTN: "landing_become_a_provider_btn",
  LANDING_SUBSCRIBE_BTN: "landing_subscribe_btn",
  LANDING_GET_THE_APP_BTN: "landing_get_the_app_btn",

  // Side Navigation
  SIDE_NAV_HOME_BTN: "side_nav_home_btn",
  SIDE_NAV_LABS_BTN: "side_nav_labs_btn",
  SIDE_NAV_FORUMS_BTN: "side_nav_forums_btn",
  SIDE_NAV_FIND_A_PROVIDER_BTN: "side_nav_find_a_provider_btn",
  SIDE_NAV_BECOME_A_PROVIDER_BTN: "side_nav_become_a_provider_btn",
  SIDE_NAV_VIDEO_BTN: "side_nav_video_btn",
  SIDE_NAV_ASK_DR_O_GPT_BTN: "side_nav_ask_dr_o_gpt_btn",
  SIDE_NAV_ASK_THE_DOC_LIVE_BTN: "side_nav_ask_the_doc_live_btn",
  SIDE_NAV_PROFILE_BTN: "side_nav_profile_btn",
  SIDE_NAV_PREMIMUM_BTN: "side_nav_premimum_btn",
  SIDE_NAV_SUPPORT_BTN: "side_nav_support_btn",
  SIDE_NAV_ABOUT_BTN: "side_nav_about_btn",
  SIDE_NAV_TERMS_OF_SERVICE_BTN: "side_nav_terms_of_service_btn",
  SIDE_NAV_PRIVACY_POLICY_BTN: "side_nav_privacy_policy_btn",
  SIDE_NAV_LOG_OUT_BTN: "side_nav_log_out_btn",
  SIDE_NAV_ABCDS_BTN: "side_nav_abcds_btn",
  SIDE_NAV_MY_HEALTH_TO_DO_LIST_BTN: "side_nav_my_health_to_do_list_btn",
  SIDE_NAV_YOUR_COURSES_BTN: "side_nav_your_courses_btn",
  SIDE_NAV_USER_SETTINGS_BTN: "side_nav_user_settings_btn",
  SIDE_NAV_LEGAL_BTN: "side_nav_privacy_policy_btn",

  // ChangePasswordScreen
  CHANGE_PASSWORD: "change_password",

  // Become A Provider Screen
  BECOME_PROVIDER_ENROLL_BTN: "become_provider_enroll_btn",

  // Enroll Screen
  ENROLL_SUBMIT_SUCCESS: "enroll_submit_success",
  ENROLL_SUBMIT_ERROR: "enroll_submit_error",

  // Find A Provider Screen
  FIND_PROVIDER_VIDEO_BTN: "find_provider_video_btn",
  FIND_PROVIDER_CERTIFIED_VIEW_BTN: "find_provider_certified_view_btn",
  FIND_PROVIDER_PAGINATION: "find_provider_pagination",
  FIND_PROVIDER_SEARCH: "find_provider_search",

  // Find A Provider View Screen
  FIND_PROVIDER_VIEW_SHARE_BTN: "find_provider_view_share_btn",
  FIND_PROVIDER_VIEW_SHARE_FACEBOOK: "find_provider_view_share_facebook",
  FIND_PROVIDER_VIEW_SHARE_WHATSAPP: "find_provider_view_share_whatsapp",
  FIND_PROVIDER_VIEW_SHARE_X: "find_provider_view_share_x",
  FIND_PROVIDER_VIEW_SHARE_LINKEDIN: "find_provider_view_share_linkedin",
  FIND_PROVIDER_VIEW_SHARE_COPY: "find_provider_view_share_copy",
  FIND_PROVIDER_VIEW_CONNECT_SUBMIT_BTN:
    "find_provider_view_connect_submit_btn",

  // Profile Screen
  PROFILE_UPDATE_PROFILE_IMAGE: "profile_update_profile_image",
  PROFILE_SUBMIT: "profile_submit",
  PROFILE_SUBMIT_SUCCESS: "profile_submit_success",
  PROFILE_SUBMIT_ERROR: "profile_submit_error",
};
