export const LOGIN = "login/LOGIN";
export const OTP_VALIDATION = "login/OTP_VALIDATION";
export const FORGOT_PASSWORD_OTP = "login/FORGOT_PASSWORD_OTP";
export const FORGOT_PASSWORD_OTP_VALIDATION =
  "login/FORGOT_PASSWORD_OTP_VALIDATION";
export const RESET_PASSWORD = "login/RESET_PASSWORD";
export const GET_IAP_SUBSCRIPTION = "signup/GET_IAP_SUBSCRIPTION";

export const SIGNUP = "signup/SIGNUP";
export const CHECK_EMAIL = "signup/CHECK_EMAIL";
export const CHECK_USER_NAME = "signup/CHECK_USER_NAME";
export const SUBSCRIBE = "signup/SUBSCRIBE";
export const GET_PAYMENT_BY_ID = "signup/GET_PAYMENT_BY_ID";
export const STRIPE_SUBSCRIPTIONS_CANCEL = "signup/STRIPE_SUBSCRIPTIONS_CANCEL";

export const GET_USER = "profile/GET_USER";
export const UPDATE_USER = "profile/UPDATE_USER";
export const UPLOAD_PROFILE = "profile/UPLOAD_PROFILE";

export const GET_VIMEO = "video/vimeo";

export const MY_LAB_GET_REPORT = "mylab/MY_LAB_GET_REPORT";
export const GET_FILE = "mylab/GET_FILE";
export const UPLOAD_LAB = "mylab/UPLOAD_LAB";
export const ATTACH_LAB = "mylab/ATTACH_LAB";

export const POST_MAIL_BAG = "mailbag/POST_MAIL_BAG";
export const POST_SUPPORT = "mailbag/POST_SUPPORT";

export const POST_USER_MEDICAL = "abcds/POST_USER_MEDICAL";
export const GET_USER_MEDICAL = "abcds/GET_USER_MEDICAL";

export const GET_ZOOM_ID = "askthedoc/GET_ZOOM_ID";
export const ZOOM_NEW_SESSION = "askthedoc/ZOOM_NEW_SESSION";
export const ZOOM_LIST = "askthedoc/ZOOM_LIST";
export const ZOOM_CREATE_SIGNATURE = "askthedoc/ZOOM_CREATE_SIGNATURE";

export const GET_TODO_LISTS = "todo/GET_TODO_LISTS";
export const GET_TODO_ITEMS = "todo/GET_TODO_ITEMS";
export const CREATE_TODO_ITEMS = "todo/CREATE_TODO_ITEMS";
export const UPDATE_TODO_ITEMS = "todo/UPDATE_TODO_ITEMS";
export const DELETE_TODO_ITEMS = "todo/DELETE_TODO_ITEMS";
export const COMPLETE_TODO_ITEMS = "todo/COMPLETE_TODO_ITEMS";
export const PENDING_TODO_ITEMS = "todo/PENDING_TODO_ITEMS";
export const GET_BY_ID_TODO_ITEMS = "todo/GET_BY_ID_TODO_ITEMS";

export const CREATE_FORUM = "forum/CREATE_FORUM";
export const UPDATE_FORUM_PASSWORD = "forum/UPDATE_FORUM_PASSWORD";
export const DISCOURSE_SSO = "forum/DISCOURSE_SSO";

export const POST_HISTORY_PHYSICAL = "historyphysical/POST_HISTORY_PHYSICAL";
export const GET_HISTORY_PHYSICAL = "historyphysical/GET_HISTORY_PHYSICAL";

export const CLINIC_SIGNUP = "becomeprovider/CLINIC_SIGNUP";
export const PUBLIC_FILES_UPLOAD = "becomeprovider/PUBLIC_FILES_UPLOAD";

export const AFFILIATE_LICENSING = "becomeprovider/AFFILIATE_LICENSING";
export const GET_CURRENT_SUBSCRIPTION =
  "becomeprovider/GET_CURRENT_SUBSCRIPTION";

export const GET_CLINICS = "findprovider/GET_CLINICS";
export const GET_CLINICS_ID = "findprovider/GET_CLINICS_ID";
export const GET_TESTOSTERONOLOGIST = "findprovider/GET_TESTOSTERONOLOGIST";
export const CONNECT_TESTOSTERONOLOGIST =
  "findprovider/CONNECT_TESTOSTERONOLOGIST";

export const USERS_ARCHIVE = "deleteaccount/USERS_ARCHIVE";

export const COUPON_VALIDATE = "billing/COUPON_VALIDATE";
