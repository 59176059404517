import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import LabelWrapper from "../LabelWrapper/LabelWrapper";
import styles from "./inputtext.module.scss";

type Props = {
  label?: string;
  labelColor?: string;
  labelSize?: number;
  error?: boolean;
  helperText?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  labelClassName?: string;
  required?: boolean;
  disabled?: boolean;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>;
};

const InputText = ({
  label,
  labelSize,
  labelColor,
  error,
  helperText,
  onChange,
  value,
  type,
  placeholder,
  fullWidth,
  sx,
  labelClassName,
  labelSx,
  required,
  disabled,
  InputProps,
}: Props) => {
  return (
    <LabelWrapper
      required={required}
      labelSx={labelSx}
      labelClassName={labelClassName}
      label={label}
      labelColor={labelColor}
      labelSize={labelSize}
    >
      <TextField
        FormHelperTextProps={{
          className: styles.helperText,
        }}
        disabled={disabled}
        InputProps={{
          style: {
            borderRadius: "12px",
          },
          ...InputProps,
        }}
        sx={sx}
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeholder}
      />
    </LabelWrapper>
  );
};

export default InputText;
