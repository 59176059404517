import axios from "axios";
import Toast from "../packages/Toast/Toast";
import { resetStore } from "../redux/store";
import { forgotPasswordApi, loginApi } from "../routes/apiRoutes";

export const useInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        Toast(error.response.data?.message, "LONG", "error");
        if (
          forgotPasswordApi !== error.response.config.url &&
          loginApi !== error.response.config.url
        ) {
          localStorage.clear();
          resetStore();
          window.location.replace("/");
        }
      }
      if (error.response?.data?.error?.name === "JsonWebTokenError") {
        Toast(error.response.data?.message, "LONG", "error");
        if (
          forgotPasswordApi !== error.response.config.url &&
          loginApi !== error.response.config.url
        ) {
          localStorage.clear();
          resetStore();
          window.location.replace("/");
        }
      } else {
        if (error.response.data?.message) {
          Toast(error.response.data?.message, "LONG", "error");
        }
      }

      return Promise.reject(error);
    }
  );
};
