import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import { memo, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Applayout from "./Applayout";
import store from "./redux/store";
import { useInterceptors } from "./utils/interceptors";
import { initFacebookPixel } from "./utils/facebookPixelHelper";

const App = () => {
  useInterceptors();
  useEffect(() => {
    // initFacebookPixel(process.env.REACT_APP_PIXEL_ID);
  }, []);

  return (
    <Provider store={store}>
      <CssBaseline />
      <Applayout />
    </Provider>
  );
};

export default memo(App);
